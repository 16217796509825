import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid'
import { MenuItem, Select } from '@mui/material'
import { formatPhoneNumber, formatToCurrency } from '../../../utils'
import { PromoterStructure } from 'types'
import MenuButton from 'screens/Promoters/MenuButton'
import { theme } from 'theme/theme'
import { StyledDiv } from './styled'

export const getPromotersColumns = ({
  setPromoter,
  openApplyPayment,
}: {
  setPromoter: (promoter: PromoterStructure) => void
  openApplyPayment: () => void
}): GridColDef[] => [
  {
    field: 'fullName',
    headerName: 'Nombre',
    flex: 1.5,
    filterable: false,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { firstName, firstLastName, secondLastName } = row
      return <div>{`${firstName} ${firstLastName} ${secondLastName}`}</div>
    },
    valueGetter: ({ row }: GridRenderCellParams) =>
      `${row.firstName} ${row.firstLastName} ${row.secondLastName}`,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { phoneNumber } = row
      return <div>{`+52 ${formatPhoneNumber(phoneNumber)}`}</div>
    },
  },
  {
    field: 'numberActiveClients',
    headerName: 'Clientes Activos',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { numberActiveClients } = row
      return <StyledDiv color={theme.palette.text.primary}>{numberActiveClients || 0}</StyledDiv>
    },
  },
  {
    field: 'totalToPay',
    headerName: 'Meta semanal',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { totalToPay, weeklyGoalDetails } = row
      const textColor = totalToPay > 0 ? theme.palette.error.main : theme.palette.text.primary

      return (
        <Select
          variant="standard"
          value={totalToPay}
          disableUnderline
          sx={{
            width: '100%',
            color: textColor,
          }}
        >
          <MenuItem key={'label'} value={totalToPay} sx={{ display: 'none' }}>
            {formatToCurrency(totalToPay)}
          </MenuItem>
          {weeklyGoalDetails.map((details: { clientName: string; nextPaymentAmount: number }) => (
            <MenuItem key={details.clientName}>{`${details.clientName} - ${formatToCurrency(
              details.nextPaymentAmount,
            )}`}</MenuItem>
          ))}
        </Select>
      )
    },
  },
  {
    field: 'depositedAmount',
    headerName: 'Monto depositado',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { depositedAmount } = row
      return <div>{formatToCurrency(depositedAmount)}</div>
    },
  },
  {
    field: 'recoveryPercentage',
    headerName: '% de recuperación',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { depositedAmount, totalToPay } = row
      const percentage = totalToPay > 0 ? (depositedAmount / totalToPay) * 100 : 0
      return <div>{`${percentage.toFixed(2)}%`}</div>
    },
  },
  {
    field: 'totalPrepaymentsAmount',
    headerName: 'Monto preaplicado',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { totalPrepaymentsAmount } = row
      return <div>{formatToCurrency(totalPrepaymentsAmount)}</div>
    },
  },
  {
    field: 'reconciledAmount',
    headerName: 'Monto conciliado',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { reconciledAmount } = row
      return <div>{formatToCurrency(reconciledAmount)}</div>
    },
  },
  {
    field: 'totalWeeklyActivatedCredits',
    headerName: 'Clientes vendidos',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { totalWeeklyActivatedCredits } = row
      return <div>{totalWeeklyActivatedCredits}</div>
    },
  },
  {
    field: 'totalActiveCreditsAmount',
    headerName: 'Monto vendido',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { totalActiveCreditsAmount } = row
      return <div>{formatToCurrency(totalActiveCreditsAmount)}</div>
    },
  },
  {
    field: 'creditBalance',
    headerName: 'Línea de crédito',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { creditBalance } = row
      return (
        <StyledDiv color={theme.palette.text.primary}>{formatToCurrency(creditBalance)}</StyledDiv>
      )
    },
  },
  {
    field: 'missingAmount',
    headerName: 'Monto Faltante',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { missingAmount } = row
      return <div>{formatToCurrency(missingAmount)}</div>
    },
  },
  {
    field: 'pendingAmount',
    headerName: 'Monto por Aplicar',
    flex: 1,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams) => {
      const { pendingAmount } = row
      return (
        <StyledDiv
          color={pendingAmount > 0 ? theme.palette.error.main : theme.palette.text.primary}
        >
          {formatToCurrency(pendingAmount)}
        </StyledDiv>
      )
    },
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => {
      return (
        <MenuButton
          openApplyPayment={openApplyPayment}
          selectPromoter={setPromoter}
          promoter={row}
        />
      )
    },
  },
]
