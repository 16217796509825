import {
  AgeOfHousingEnum,
  Credit,
  CreditStatusEnum,
  HousingTypeEnum,
  MaritalStatusEnum,
  PaymentType,
  PersonalData,
  ProofOfAddressEnum,
  RejectedTypeEnum,
  RejectionStatusEnum,
  StatusClientEnum,
} from 'types'

export const defaultProspectValue = {
  id: '',
  firstName: '',
  firstLastName: '',
  secondLastName: '',
  phoneNumber: '',
  phoneLada: '',
  imageUrl: '',
  status: StatusClientEnum.CREATED,
  createdAt: new Date(),
  createdBy: '',
  personalData: {
    id: '',
    firstName: '',
    firstLastName: '',
    secondLastName: '',
    maritalStatus: MaritalStatusEnum.SINGLE,
    frontINEUrl: '',
    backINEUrl: '',
    proofOfAddressUrl: '',
    proofOfAddress: ProofOfAddressEnum.CFE,
    photoUrl: '',
  },
  contactData: {
    id: '',
    phoneNumber: '',
    phoneLada: '',
    phoneVerified: false,
    outsideAddressPhotoUrl: '',
    insideAddressPhotoUrl: '',
    latitude: 0,
    longitude: 0,
    outdoorNumber: '',
    indoorNumber: '',
    state: '',
    municipality: '',
    zipCode: '',
    street: '',
    suburb: '',
  },
  economicData: {
    id: '',
    housingType: HousingTypeEnum.OWNED,
    ageOfHousing: AgeOfHousingEnum.LESS_THAN_1_YEAR,
    economicDependants: 0,
    incomes: [],
  },
  acceptedAt: new Date(),
  rejections: [
    {
      contactData: false,
      createdAt: '',
      economicData: false,
      id: '',
      internalReason: '',
      personalData: true,
      reason: '',
      status: RejectionStatusEnum.PENDING,
      type: RejectedTypeEnum.MANUAL,
      updatedAt: '',
    },
  ],
}

export const defaultPersonalData: PersonalData = {
  id: '',
  firstName: '',
  firstLastName: '',
  secondLastName: '',
  maritalStatus: MaritalStatusEnum.SINGLE,
  frontINEUrl: '',
  backINEUrl: '',
  proofOfAddressUrl: '',
  proofOfAddress: ProofOfAddressEnum.CFE,
  photoUrl: '',
}

export const defaultContactData = {
  id: '',
  phoneNumber: '',
  phoneLada: '',
  phoneVerified: false,
  outsideAddressPhotoUrl: '',
  insideAddressPhotoUrl: '',
  latitude: 0,
  longitude: 0,
  street: '',
  municipality: '',
  suburb: '',
  zipCode: '',
  outdoorNumber: '',
  indoorNumber: '',
  state: '',
}

export const defaultCreditData: Credit = {
  id: '1f3e2103-dc1d-4205-8b18-5172b4d3a615',
  clientId: 'd01ad2bd-5be5-48b4-87a1-648ec1ca7bb7',
  amount: 1000,
  weeksSelected: 8,
  paymentType: 'PAYMENT_ORDER' as PaymentType,
  speiNumber: '',
  videoUrl: '',
  videoKey: '',
  signUrl: '',
  signKey: '',
  weeklyPayment: 158,
  status: 'ACTIVE' as CreditStatusEnum,
  lendusId: null,
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  creditDetails: null,
  pendingType: null,
  legalPDF: '',
  pdfKey: '',
  previousCreditId: '',
}
