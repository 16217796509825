import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from 'screens/About'
import PrivateRoute from './permissions/PrivateRoute'
import DashboardLayout from 'layouts/DashboardLayout'
import Login from 'screens/Login'
import Promoters from 'screens/Promoters'
import ValidatorLayout from 'layouts/ValidatorLayout'
import Validator from 'screens/Validator'
import Prospects from 'screens/Prospects'
import PaymentsOrders from 'screens/Payments'
import CreditsValidator from 'screens/CreditsValidator'
import BankTransfers from 'screens/BankTransfers'
import PromoterReports from 'screens/PromoterReports'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/" element={<Login />} />
        <Route path="/Login" element={<Login />} />
        <Route
          path="/promoters"
          element={<PrivateRoute layout={DashboardLayout} component={Promoters} />}
        />
        <Route
          path="/validate/:id"
          element={<PrivateRoute layout={ValidatorLayout} component={Validator}></PrivateRoute>}
        />
        <Route
          path="/validate-credit/:id"
          element={<PrivateRoute layout={ValidatorLayout} component={CreditsValidator} />}
        />
        <Route
          path="/promoterreports/:id"
          element={
            <PrivateRoute layout={ValidatorLayout} component={PromoterReports}></PrivateRoute>
          }
        />
        <Route
          path="/prospects"
          element={<PrivateRoute layout={DashboardLayout} component={Prospects} />}
        />
        <Route
          path="/paymentorders"
          element={<PrivateRoute layout={DashboardLayout} component={PaymentsOrders} />}
        />
        <Route
          path="/banktransfers"
          element={<PrivateRoute layout={DashboardLayout} component={BankTransfers} />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
