import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Typography,
  FormControlLabel,
} from '@mui/material'
import { clientsByPromoterReport } from 'types'

interface PromoterSelectProps {
  promoters: clientsByPromoterReport[]
  onSelectionChange: (selected: clientsByPromoterReport[]) => void
  inputLb: string
  loading: boolean
}

const PromoterSelectWithSearch: React.FC<PromoterSelectProps> = ({
  promoters,
  onSelectionChange,
  inputLb,
  loading,
}) => {
  const [selectedPromoters, setSelectedPromoters] = useState<string[]>(
    promoters.map((promoter) => promoter.id),
  )

  useEffect(() => {
    if (!loading && promoters.length > 0) {
      setSelectedPromoters(promoters.map((promoter) => promoter.id))
    }
  }, [loading, promoters])

  useEffect(() => {
    if (!loading && promoters.length > 0) {
      const selectedPromotersData = promoters.filter((promoter) =>
        selectedPromoters.includes(promoter.id),
      )
      onSelectionChange(selectedPromotersData)
    }
  }, [loading, promoters, onSelectionChange, selectedPromoters])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    const selectedIds = event.target.value as string[]
    setSelectedPromoters(selectedIds)

    const selectedPromotersData = promoters.filter((promoter) => selectedIds.includes(promoter.id))
    onSelectionChange(selectedPromotersData)
  }
  // Manejo del checkbox para seleccionar todos
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedPromoters(promoters.map((promoter) => promoter.id))
      onSelectionChange(promoters)
    } else {
      setSelectedPromoters([])
      onSelectionChange([])
    }
  }

  const isAllSelected = selectedPromoters.length === promoters.length && promoters.length > 0

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="promoter-select-label">{`${inputLb}`}</InputLabel>
      <Select
        labelId="promoter-select-label"
        id="promoter-select"
        multiple
        value={selectedPromoters}
        onChange={handleChange}
        input={<OutlinedInput label={`${inputLb}`} />}
        renderValue={(selected) =>
          isAllSelected
            ? 'Todos'
            : selected.map((id) => promoters.find((p) => p.id === id)?.name).join(', ')
        }
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 300 },
          },
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={isAllSelected} onChange={handleSelectAll} />}
          label="Seleccionar todos"
        />
        {promoters.length > 0 ? (
          promoters.map((promoter) => (
            <MenuItem key={promoter.id} value={promoter.id}>
              <Checkbox checked={selectedPromoters.includes(promoter.id)} />
              <ListItemText primary={promoter.name} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <Typography>No se encontraron resultados</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default PromoterSelectWithSearch
