import { StyledHeader, StyledNameContainer, StyledIconButton } from './styled'
import { Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

interface Props {
  handleClickCloseValidatorScreen: () => void
  name?: string
}

const TopBarReports = ({ handleClickCloseValidatorScreen, name }: Props) => {
  return (
    <StyledHeader>
      <StyledNameContainer>
        <StyledIconButton onClick={handleClickCloseValidatorScreen}>
          <Close />
        </StyledIconButton>
        <Typography variant="h3">{`Reportes promotor ${name}`}</Typography>
      </StyledNameContainer>
    </StyledHeader>
  )
}

export default TopBarReports
