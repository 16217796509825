/* eslint-disable */
import React,{useEffect,useState} from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { StyledContainer } from './styled'
import TopBarReports from 'components/TopBarReports'
import MultiSelect from 'components/MultiSelect'
import SelectCustom from 'components/SelectCustom'
import DateRangePicker from 'components/DatePicker'
import { Button, CircularProgress, Backdrop, Box, IconButton, Modal, Typography,Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_CLIENTS_BY_PROMOTER, GET_PREPAYMENTS_BY_PROMOTER,GET_DEPOSIT_BY_PROMOTER } from './queries'
import {
  clientsByPromoterReport,
  prePaymentDeposit,
} from 'types'
import DataGridReports from 'components/DataGridReports'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { DateRange } from 'react-day-picker'
import dayjs from 'dayjs'
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'

const columns: GridColDef[] = [
  { field: 'id', headerName: 'IdPrePayment', width: 120 },
  { field: 'clientFullName', headerName: 'Nombre cliente', width: 220 },
  { field: 'totalAmount', headerName: 'Monto', width: 80 },
  { field: 'createdDate', headerName: 'Fecha-hora Creación', width: 190 },
  { field: 'telefono', headerName: 'Teléfono', width: 180 },
  { field: 'status', headerName: 'Status', width: 90 },
  { field: 'creditId', headerName: 'CreditId', width: 380 },
  { field: 'lendusId', headerName: 'LendusId', width: 200 },
  { field: 'depositId', headerName: 'DepositId', width: 200 },
  { field: 'conciliationId', headerName: 'conciliationId', width: 200 },
]

const columnsTwo: GridColDef[] = [
  { field: 'id', headerName: 'IdDeposito', width: 120 },
  { field: 'amount', headerName: 'Monto', width: 80 },
  { field: 'createdAt', headerName: 'Fecha Creación', width: 150 },
  { field: 'dateApplication', headerName: 'Fecha Aplicación', width: 150 },
  { field: 'status', headerName: 'Status', width: 90 },
  { field: 'referenceNumber', headerName: 'ReferenceNumber', width: 90 },
  {
    field: 'prePaymentsReport',
    headerName: 'Prepagos Afectados',
    width: 200,
    renderCell: (params) => <ExpandableCell nestedData={params.value} />,
  },
  { field: 'conciliationId', headerName: 'ConciliationId', width: 240 },
  { field: 'bankType', headerName: 'BankType', width: 120 },
  { field: 'authorizationNumber', headerName: 'AuthorizationNumber', width: 120 },
]


const ExpandableCell = ({ nestedData }: { nestedData: any[] }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  const totalAmount = nestedData?.reduce((sum, item) => sum + (item.totalAmount || 0), 0) || 0;

  
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: open ? '#f0f0f0' : 'transparent',
          padding: 1,
          borderBottom: '1px solid #e0e0e0',
          cursor: 'pointer',
        }}
        onClick={toggleModal}
      >
        <Typography variant="body2">
          {nestedData ? `(${nestedData.length} Prepagos. $${totalAmount} )` : 'Sin datos'}
        </Typography>
        <IconButton size="small">
          {open ? <RemoveRedEye /> : <RemoveRedEye />}
        </IconButton>
      </Box>

      {/* Modal para mostrar la información */}
      <Modal open={open} onClose={toggleModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
          sx={{
            width: '80%',
            maxHeight: '80%',
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            overflowY: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <div style={{display: 'flex', justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column'}}>
            <Typography variant="h6">Detalles de prepagos</Typography>
            <Typography variant="body2">{`Monto total de prepagos: $${totalAmount}`}</Typography>
            </div>
            <IconButton onClick={toggleModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {nestedData && nestedData.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Monto</TableCell>
                  <TableCell>Nombre Completo</TableCell>
                  <TableCell>Crédito</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Conciliación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nestedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.totalAmount}</TableCell>
                    <TableCell>{item.clientFullName}</TableCell>
                    <TableCell>{item.creditId}</TableCell>
                    <TableCell>{item.telefono}</TableCell>
                    <TableCell>{item.conciliationId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="body2">Sin datos disponibles</Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

const PromoterReports: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { id } = useParams<{ id?: string }>()
  const { name } = location.state || {};
  const [clientsDat, setClientsDat] = useState<clientsByPromoterReport[]>([]);
  const [clientsSelected, setClientSelected] = useState<clientsByPromoterReport[]>([]);
  const [prePayGrid, setPrePayGrid] = useState<GridRowsProp[]>([]);
  const [depositGrid, setDepositGrid] = useState<GridRowsProp[]>([]);

  const { loading, data} = useQuery(GET_CLIENTS_BY_PROMOTER, {
    variables: {
      userId: id,
    },
  })

  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>()
  const [selectedInicio, setSelectedInicio] = useState<string | undefined>()
  const [selectedFinal, setSelectedFinal] = useState<string | undefined>()
  const [reportSelected, setReportSelected] = useState<string | undefined>('1')

  const handleDateRangeChange = (range: DateRange | undefined) => {
    setSelectedRange(range)
    if (range?.from && range.to) {
      const fromFormatted = dayjs(range.from).startOf('day').toISOString()
      const toFormatted = dayjs(range.to).startOf('day').toISOString()
     setSelectedInicio(fromFormatted.toString());
     setSelectedFinal(toFormatted.toString());
    }
  }

  const [fetchPrePayments, { loading: loadingPrePay, data: prePaymentsData }] =
    useLazyQuery(GET_PREPAYMENTS_BY_PROMOTER);

    const [fetchDeposit, { loading: loadingDeposit, data: DepositData }] =
    useLazyQuery(GET_DEPOSIT_BY_PROMOTER);

  const handleButtonClick = () => {
    var clientIds = clientsSelected.map((item) => item.id);
    if(reportSelected == "1"){
      fetchPrePayments({
        variables: {
          promoterId: id,
          fechaInicio: selectedInicio,
          fechaFin: selectedFinal,
          clientIds: clientIds,
        },
      });
    }else{
      fetchDeposit({
        variables: {
          promoterId: id,
          fechaInicio: selectedInicio,
          fechaFin: selectedFinal,
          clientIds: clientIds,
        },
      });
    }
    
  };

  useEffect(() => {
    console.log("intento2");
    if (prePaymentsData) {
      const formattedData = prePaymentsData.getPrePayByPromoter.map(
        (payment: { id: string; totalAmount: number; createdDate: string; status: string; creditId: string; lendusId: string; depositId: string; conciliationId: string; clientFullName: string; telefono: string; }) => {
          const date = new Date(payment.createdDate);
          const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'America/Mexico_City',
          };
          const formattedDate = new Intl.DateTimeFormat('es-MX', options).format(date);
          return {
            id: payment.id,
            totalAmount: payment.totalAmount,
            createdDate: formattedDate,
            status: payment.status,
            creditId: payment.creditId,
            lendusId: payment.lendusId,
            depositId: payment.depositId,
            conciliationId: payment.conciliationId,
            clientFullName: payment.clientFullName,
            telefono: payment.telefono,
          };
        }
      );
      
      console.log(formattedData);
      //setPrePayGrid([...formattedData]);
      setPrePayGrid(formattedData.length > 0 ? formattedData : []);
    }
  }, [JSON.stringify(prePaymentsData)]);

  useEffect(() => {
    console.log("Deposit");
    if (DepositData) {
      const formattedData = DepositData.getDepositReport.map(
        (deposit: { id: string; dateApplication: string; referenceNumber: string; amount: number; bankType: string;
 authorizationNumber: string; createdAt: string; status: string; updatedAt: string; conciliationId: string; prePaymentsReport: prePaymentDeposit }) => {
          const dateCreated = new Date(deposit.createdAt);
          const dateApplication = new Date(deposit.dateApplication);
          const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'America/Mexico_City',
          };
          const createFormattedDate = new Intl.DateTimeFormat('es-MX', options).format(dateCreated);
          const aplicationFormattedDate = new Intl.DateTimeFormat('es-MX', options).format(dateApplication);
          return {
            id: deposit.id,
            dateApplication: aplicationFormattedDate,
            referenceNumber: deposit.referenceNumber,
            amount: deposit.amount,
            bankType: deposit.bankType,
            authorizationNumber: deposit.authorizationNumber,
            createdAt: createFormattedDate,
            status: deposit.status,
            updatedAt: deposit.updatedAt,
            prePaymentsReport: deposit.prePaymentsReport,
            conciliationId: deposit.conciliationId,
          };
        }
      );
      
      console.log(formattedData);
      setDepositGrid(formattedData.length > 0 ? formattedData : []);
    }
  }, [JSON.stringify(DepositData)]);
  
  const handleClientsSelection = (selected: { id: string; name: string }[]) => {
    setClientSelected(selected);
  };
  const handleOptionSelection = (selected: { id: string; name: string } | null) => {
    setReportSelected(selected?.id)
    console.log(selected?.id);
  };
  const goBackFunction = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!loading && data) {
      const dat = data?.getClientsByPromoter
      const formattedClientsData = dat.map((client: { id: string; firstName: string; firstLastName: string; secondLastName: string }) => ({
        id: client.id,
        name: `${client.firstName} ${client.firstLastName} ${client.secondLastName}`.trim(),
      }));
      console.log(formattedClientsData);
      setClientsDat(formattedClientsData)
    }
  }, [id, data, loading])


  const reportsOptions = [
    { id: '1', name: 'Pagos Registrados' },
    { id: '2', name: 'Depósitos aplicados' },
  ];

  return (
    <StyledContainer>
      <Backdrop
        open={loadingPrePay || loadingDeposit}
        style={{ zIndex: 1301, color: '#fff' }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TopBarReports
        handleClickCloseValidatorScreen={goBackFunction}
        name={name}
      />
      <div style={{display:'flex' ,flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
      <SelectCustom
        opciones={reportsOptions}
        onSelectionChange={handleOptionSelection}
        inputLabel='Tipo de reporte'
        defaultValue='1'
      />
      <MultiSelect
        promoters={clientsDat}
        onSelectionChange={handleClientsSelection}
        inputLb='Clientes'
        loading = {loading}
      />
      <DateRangePicker 
      selectedRange={selectedRange}
      setSelectedRange={handleDateRangeChange}
      />
      <Button
          onClick={handleButtonClick}
          style={{ marginLeft: 15 }}
          variant="contained"
          disabled={!clientsSelected.length || !selectedRange?.from || !selectedRange?.to}
        >
          Ver Reporte
        </Button>
      </div>
      <div style={{ marginLeft: 15, marginRight: 15 }}>
      { reportSelected == '1' ?
      <DataGridReports columns={columns} typeReport={reportSelected || ''} rows={prePayGrid} /> :
      <DataGridReports columns={columnsTwo} typeReport={reportSelected || ''} rows={depositGrid} />
      }
    </div>
    </StyledContainer>
  )
}

export default PromoterReports
