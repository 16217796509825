import React, { useState } from 'react'
import { Menu, MenuItem, Typography, ListItemIcon } from '@mui/material'
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'
import Payment from '@mui/icons-material/Payment'
import RequestQuote from '@mui/icons-material/RequestQuote'
import { StyledIconButton } from './styled'
import { theme } from 'theme/theme'
import { PromoterStructure } from 'types'
import { useNavigate } from 'react-router-dom'

interface Props {
  promoter: PromoterStructure
  openApplyPayment: () => void
  selectPromoter: (promoter: PromoterStructure) => void
}

const MenuButton = ({ selectPromoter, openApplyPayment, promoter }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // const handleWatchPromoter = () => {
  //   //TODO add function
  //   console.log('click')
  // }
  // const handleRestorePassword = () => {
  //   //TODO add function
  //   console.log('click')
  // }

  return (
    <div>
      <StyledIconButton onClick={handleClick}>
        <MoreVertSharpIcon />
      </StyledIconButton>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleWatchPromoter}>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Ver Promotora
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleRestorePassword}>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Restablecer Contraseña
          </Typography>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            selectPromoter(promoter)
            openApplyPayment()
          }}
        >
          <ListItemIcon>
            <Payment />
          </ListItemIcon>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Aplicar Pago
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(`/promoterreports/${promoter.id}`, {
              state: {
                name: `${promoter.firstName} ${promoter.firstLastName} ${promoter.secondLastName}`,
              },
            })
          }}
        >
          <ListItemIcon>
            <RequestQuote />
          </ListItemIcon>
          <Typography variant="body1" color={theme.typographyColor.primaryText}>
            Reportes
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default MenuButton
