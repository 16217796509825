export type RouteType = {
  path: string
}

export const routes: RouteType[] = [
  { path: '/promoters' },
  { path: '/prospects' },
  { path: '/paymentorders' },
  { path: '/banktransfers' },
  { path: '/validate' },
  { path: '/validate-credit' },
  { path: '/promoterreports' },
]
