import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Typography } from '@mui/material'

interface Opcion {
  id: string
  name: string
}

interface SingleSelectProps {
  opciones: Opcion[]
  onSelectionChange: (selected: Opcion | null) => void
  inputLabel: string
  defaultValue: string
}

const SingleSelect: React.FC<SingleSelectProps> = ({
  opciones,
  onSelectionChange,
  inputLabel,
  defaultValue = '',
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    const selectedId = event.target.value as string
    setSelectedOption(selectedId)

    const selectedOptionData = opciones.find((opcion) => opcion.id === selectedId) || null
    onSelectionChange(selectedOptionData)
  }

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="single-select-label">{inputLabel}</InputLabel>
      <Select
        labelId="single-select-label"
        id="single-select"
        value={selectedOption}
        onChange={handleChange}
        input={<OutlinedInput label={inputLabel} />}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 300 },
          },
        }}
      >
        {opciones.length > 0 ? (
          opciones.map((opcion) => (
            <MenuItem key={opcion.id} value={opcion.id}>
              {opcion.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <Typography>No se encontraron resultados</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
