import React, { useRef } from 'react'
import { Box, TextField, Popover, IconButton, InputAdornment } from '@mui/material'
import { DayPicker, DateRange } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import dayjs from 'dayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

interface DateRangePickerInputProps {
  selectedRange: DateRange | undefined
  setSelectedRange: (range: DateRange | undefined) => void
}

const DateRangePickerInput: React.FC<DateRangePickerInputProps> = ({
  selectedRange,
  setSelectedRange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const formatRange = () => {
    if (selectedRange?.from && selectedRange.to) {
      const fromFormatted = dayjs(selectedRange.from).format('DD/MM/YYYY')
      const toFormatted = dayjs(selectedRange.to).format('DD/MM/YYYY')
      return `${fromFormatted} - ${toFormatted}`
    }
    return ''
  }

  return (
    <>
      {/* Input con el Popover */}
      <TextField
        label="Rango de fechas"
        variant="outlined"
        value={formatRange()}
        onClick={handleOpenPopover}
        ref={inputRef}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleOpenPopover}>
                <CalendarMonthIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* Popover para mostrar el calendario */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <DayPicker
            mode="range"
            selected={selectedRange}
            onSelect={setSelectedRange}
            numberOfMonths={1}
          />
        </Box>
      </Popover>
    </>
  )
}

export default DateRangePickerInput
