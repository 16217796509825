import { gql } from '@apollo/client'

export const GET_CLIENTS_BY_PROMOTER = gql`
  query GetClientsByPromoter($userId: String!) {
    getClientsByPromoter(userId: $userId) {
      id
      firstName
      firstLastName
      secondLastName
    }
  }
`
export const GET_PREPAYMENTS_BY_PROMOTER = gql`
  query GetPrePayByPromoter(
    $promoterId: String!
    $fechaInicio: String!
    $fechaFin: String!
    $clientIds: [String!]!
  ) {
    getPrePayByPromoter(
      promoterId: $promoterId
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      clientIds: $clientIds
    ) {
      id
      totalAmount
      status
      createdDate
      lendusId
      creditId
      depositId
      conciliationId
      clientFullName
      telefono
    }
  }
`
export const GET_DEPOSIT_BY_PROMOTER = gql`
  query GetDepositReport(
    $promoterId: String!
    $fechaInicio: String!
    $fechaFin: String!
    $clientIds: [String!]!
  ) {
    getDepositReport(
      promoterId: $promoterId
      fechaInicio: $fechaInicio
      fechaFin: $fechaFin
      clientIds: $clientIds
    ) {
      id
      dateApplication
      referenceNumber
      amount
      bankType
      authorizationNumber
      createdAt
      status
      updatedAt
      conciliationId
      prePaymentsReport {
        id
        totalAmount
        conciliationId
        creditId
        clientFullName
        telefono
      }
    }
  }
`
