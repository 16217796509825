export type AnimalResponse = {
  message: string
  status: string
}

export interface User {
  email: string
  password: string
}

export enum SizeEmptyErrorState {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

export interface PromoterStructure {
  id: number
  firstName: string
  firstLastName: string
  secondLastName: string
  phoneNumber: string
  createdAt: string
  status: string
  creditBalance?: number
}

export interface AWSStructure {
  session?: null
  attributes?: {
    sub: string
  }
  authenticationFlowType?: string
  client?: null
  keyPrefix?: string
  pool?: null
  preferredMFA?: string
  signInUserSession?: {
    accessToken?: {
      jwtToken?: string
      payload?: null
    }
    idToken?: {
      payload: {
        family_name: string
        given_name: string
        'cognito:groups': string[]
        exp: number
      }
    }
    refreshToken?: null
  }
  storage?: null
  userDataKey?: string
  username?: string
}

export interface Prospect {
  id: string
  firstName: string
  firstLastName: string
  secondLastName: string
  phoneNumber: string
  phoneLada: string
  imageUrl: string
  status: StatusClientEnum
  personalData?: PersonalData
  contactData?: ContactData
  economicData?: EconomicData
  createdBy: string
  createdAt: Date
  acceptedAt?: Date
  rejections?: Rejection[]
}

export interface PersonalData {
  id: string
  firstName: string
  firstLastName: string
  secondLastName: string
  maritalStatus: MaritalStatusEnum
  frontINEUrl: string
  backINEUrl: string
  proofOfAddressUrl: string
  proofOfAddress: ProofOfAddressEnum
  photoUrl: string
}

export interface ContactData {
  id: string
  phoneNumber: string
  phoneLada: string
  phoneVerified: boolean
  outsideAddressPhotoUrl: string
  insideAddressPhotoUrl: string
  latitude: number
  longitude: number
  outdoorNumber: string
  indoorNumber: string | null
  suburb: string
  zipCode: string
  state: string
  street: string
  municipality: string
}

export interface EconomicData {
  id: string
  housingType: HousingTypeEnum
  ageOfHousing: AgeOfHousingEnum
  economicDependants: number
  incomes: Income[]
}

export interface Income {
  id?: string
  incomeType?: IncomeTypeEnum | null
  description?: string | null
  amount: number
  periodicity: IncomePeriodicityEnum
  nameOfEmployer?: string | null
  workExperience?: WorkingLengthEnum | null
  employmentPosition?: string | null
  economicData?: EconomicData
  isMain: boolean
}

export enum StatusClientEnum {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  PREREJECTED = 'PREREJECTED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
}

export enum RejectionStatusEnum {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
}

export enum MaritalStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum ProofOfAddressEnum {
  CFE = 'CFE',
  WATER = 'WATER',
  TELMEX = 'TELMEX',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  PLACE = 'PLACE',
}

export enum HousingTypeEnum {
  OWNED = 'OWNED',
  RENTED = 'RENTED',
  BORROWED = 'BORROWED',
}

export enum AgeOfHousingEnum {
  LESS_THAN_1_YEAR = 'LESS_THAN_1_YEAR',
  BETWEEN_1_AND_5_YEARS = 'BETWEEN_1_AND_5_YEARS',
  BETWEEN_5_AND_10_YEARS = 'BETWEEN_5_AND_10_YEARS',
  BETWEEN_10_AND_20_YEARS = 'BETWEEN_10_AND_20_YEARS',
  MORE_THAN_20_YEARS = 'MORE_THAN_20_YEARS',
}

export enum IncomeTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  INDEPENDENT = 'INDEPENDENT',
  OTHER = 'OTHER',
}

export enum IncomePeriodicityEnum {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum WorkingLengthEnum {
  LESS_THAN_1_YEAR = 'LESS_THAN_1_YEAR',
  BETWEEN_1_AND_3_YEARS = 'BETWEEN_1_AND_3_YEARS',
  BETWEEN_3_AND_5_YEARS = 'BETWEEN_3_AND_5_YEARS',
  MORE_THAN_5_YEARS = 'MORE_THAN_5_YEARS',
}

export enum DocumentsType {
  INEFRONT = 1,
  INEBACK = 2,
  CFE = 3,
  WATER = 4,
  PHONE = 5,
  BANK_ACCOUNT = 6,
  PLACE = 7,
  OUTSIDE_HOUSE = 8,
  INSIDE_HOUSE = 9,
}

export enum RejectedTypeEnum {
  AUTOMATIC_VERIFICATION = 'AUTOMATIC_VERIFICATION',
  MANUAL = 'MANUAL',
}

export enum ValidationStep {
  FIRST_STEP = 0,
  SECOND_STEP = 1,
}

export interface ClientsCount {
  accepted: number
  automaticVerification: number
  pending: number
  rejected: number
}

export enum PaymentOrderStep {
  PENDING_REVIEW = 0,
  PENDING = 1,
  APPLIEDS = 2,
  DISBURSEDS = 3,
  REJECTED = 4,
}

export enum BankTransfersTabs {
  PENDING = 0,
  ACCEPTED = 1,
  DISBURSED = 2,
  REJECTED = 3,
}

export enum PaymentSortModel {
  CREATION = 'createdAt',
  APPLIED = 'appliedDate',
  DISBURSEMENT = 'disbursementDate',
  UPDATED = 'updatedAt',
}

export enum PaymentType {
  SPEI = 'SPEI',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
}

export interface PaymentsCount {
  pending: number
  applied: number
  disbursed: number
}

export interface Rejection {
  contactData: boolean
  createdAt: string
  economicData: boolean
  id: string
  internalReason: string
  personalData: boolean
  reason: string
  status: RejectionStatusEnum
  type: RejectedTypeEnum
  updatedAt: string
}

export interface PaymentOrder {
  id: string
  creditId: string
  clientFullName: string
  clientPhoneNumber: string
  clientPhoneLada: string
  promoterFullName: string
  amount: number
  status: PaymentOrderStatus
  referenceNumber?: string | null
  appliedDate?: Date | null
  disbursementDate?: Date | null
  createdAt: Date
  updatedAt: Date
}

export enum PaymentOrderStatus {
  APPLIED = 'APPLIED',
  DISBURSED = 'DISBURSED',
  PENDING = 'PENDING',
}

export interface Bank {
  id: string
  bankName: string
  accountNumber: string
  beneficiaryName: string
  enumBankName: string
}

export enum CreditStatus {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export enum PendingType {
  STP = 'STP',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export type Credit = {
  id: string
  clientId: string
  amount: number
  weeksSelected: number
  paymentType: PaymentType
  speiNumber?: string | null
  videoUrl: string
  videoKey: string
  signUrl: string
  signKey: string
  weeklyPayment: number
  status: CreditStatusEnum
  lendusId?: string | null
  createdBy: string
  createdAt: string
  updatedAt: string
  creditDetails?: CreditDetails | null
  prePayments?: PrePayment[] | null
  pendingType?: PendingTypeEnum | null
  creditId?: string
  legalPDF?: string
  pdfKey?: string
  rejections?: Rejection[]
  previousCreditId?: string | null
}

export enum CreditStatusEnum {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
}

export type CreditDetails = {
  id: string
  clientName: string
  totalAmount: number
  amountPaid?: number
  weeklyPaymentAmount: number
  nextPaymentAmount?: number
  nextPaymentDate: string
  paymentsMade?: number
  paymentsNumber?: number
  moratoriumAmount?: number
  status: string
  businessCaseStatus: BusinessCasesStatus
  daysMora: number
  updatedAt: string
  creditId?: string | null
}

export enum BusinessCasesStatus {
  OVERDUE = 'OVERDUE',
  LATE_PAYMENT = 'LATE_PAYMENT',
  UP_TO_DATE = 'UP_TO_DATE',
  NEW = 'NEW',
}

export type PrePayment = {
  id: string
  totalAmount: number
  paymentAmount: number
  moratoriumAmount: number
  appliedDate: string
  status: PrePaymentStatusEnum
  createdDate: string
  createdBy: string
  lendusId?: string | null
  creditId: string
  paymentDetails?: PaymentDetails
}

export enum PendingTypeEnum {
  STP = 'STP',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum PrePaymentStatusEnum {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  APPLIED = 'APPLIED',
}

export type PaymentDetails = {
  id: string
  paymentDate: string
  paymentAmount: number
  surcharge: number
  total: number
  speiNumber?: string
  videoUrl: string
  signUrl: string
  weeklyPayment: number
  status: CreditStatus
  lendusId?: string
  paymentOrder?: PaymentOrder
  createdBy: string
  createdAt: string
  updatedAt: string
  productId?: string
  pendingType?: PendingType
  startDate?: Date
  endDate?: Date
  legalPDF: string
}

export type CreditByStatus = {
  id: string
  clientName: string
  phone: string
  createdAt: string
  amount: number
  dispersionAmount: number
  speiNumber?: string
}

export type clientsByPromoterReport = {
  id: string
  name: string
}

export type prePaymentDeposit = {
  id: string
  totalAmount: string
  conciliationId: string
  creditId: string
  clientFullName: string
  telefono: string
}

export type DisbursedCredit = PaymentOrder | CreditByStatus
